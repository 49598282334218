.item-group {
  display: flex;
  gap: 1em;
  margin-bottom: 0.5em;
}
.item-group > span:first-child {
  flex: 1;
  white-space: nowrap;
}

/* downloading */
.downloading {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(195, 195, 195, 0.3);
}
.downloading-container {
  background-color: white;
  min-width: 250px;
  padding: 1rem;
  text-align: center;
  border-radius: 4px;
}
.downloading span {
  font-size: 1.1rem !important;
  color: black;
}
.downloading-status {
  width: 0px;
  height: 2px;
  background-color: #b51818;
  display: block;
  margin-top: 0.8rem;
  animation-name: downloading;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
@keyframes downloading {
  from {
    width: 0px;
  }
  to {
    width: 100%;
  }
}

.zoom {
  position: fixed;
  right: .5rem;
  bottom: 80px;
  z-index: 1000;
}

.text-black{
  color: black !important;
}