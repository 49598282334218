.app-container {
  /* max-width: 400px !important;
  display: block;
  margin: 0 auto; */
  background-color: white !important;
  overflow: hidden;
}

.main-container {
  padding-top: 80px;
  padding-bottom: 80px;
}

.login-container {
  background-color: transparent;
  padding: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

.login-container .card {
  max-width: 350px !important;
}

/* color */
.text-link {
  color: #0d6efd !important;
}

/* modal */
.custom-modal-body {
  max-height: 400px;
  overflow-y: auto;
}
.custom-modal-body .input-group {
  align-items: center;
}
.custom-modal-body .input-group-text {
  background-color: #cccccc;
  padding: 0.5em;
  height: 46px;
  color: #b51818;
}
.custom-modal-body div,
.custom-modal-body span {
  font-size: 1.1rem !important;
}

button,
p,
div,
span,
input,
select,
label {
  font-size: 1rem !important;
}
.btn-lg {
  height: 45px !important;
}

.invalid {
  color: #b51818;
  font-size: small;
  font-style: italic;
  margin-top: 6px;
}
.invalid-black {
  color: #666666;
  font-size: small;
  font-style: italic;
  margin-top: 6px;
}

.text-small {
  font-size: small;
  font-style: italic;
}

/* form */
.form-group label {
  font-size: 0.9rem !important;
  margin-bottom: 0.5rem !important;
  color: #000000 !important;
}
.form-group.basic input {
  font-size: 1.2rem !important;
  margin-top: 6px !important;
  margin-bottom: 6px !important;
}

/* icon */
.ans-whatsapp {
  background-image: url("./img/whatsapp.svg");
  background-size: contain;
  display: inline-block;
  width: 20px;
  height: 20px;
}

input::placeholder {
  opacity: 0.3 !important;
}

.item label {
  opacity: 0.7 !important;
}

.item .text-muted {
  color: #000000 !important;
}
